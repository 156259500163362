<template>
  <v-card aut-form-editor-page>
    <v-card-title> Edit Form </v-card-title>
    <v-card-text> Culpa dolor non non sunt velit. </v-card-text>
  </v-card>
</template>
<script>
const debug = require("debug")("atman.components.form_editor");
debug(`form_editor`);
export default {
  name: "EditForm",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
